<template>
  <el-container class="ib">
    <el-aside width="auto">
      <ib-menu></ib-menu>
    </el-aside>
    <div style="width: 100%;overflow: auto;">
      <bread-crumb />
      <el-main class="main-cnt">
        <router-view></router-view>
      </el-main>
    </div>
  </el-container>
</template>

<script>
import ibMenu from "@/components/leftMenu/Index.vue";
import BreadCrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    ibMenu,
    BreadCrumb
  },
  async mounted() {
    await this.$store.dispatch("getSessionId");
  }
};
</script>

<style lang="less">
.el-container {
  height: 100%;
  overflow: hidden;
}
</style>
